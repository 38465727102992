require("./static/styles/nishi.less");
require("./static/styles/App.less");
require("./static/styles/khushali.less");
require("./static/styles/kajal.less");
require("./static/styles/responsive.less");
require("./static/styles/custom.less");
require("./static/styles/achievement.css");
require("./static/styles/fontawesome-all.min.css");
require("./static/styles/presence.css");
require('antd/dist/antd.css');
/*
const React  = require("react");
const addScript = url => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script)
};

exports.onClientEntry = () => {
    window.onload = () => {
        addScript("/js/buro-workers.min.js");
        addScript("/js/main-engine.min.js");
        addScript("/js/modernizr.custom.js");
    }
};*/
/*export const onServiceWorkerUpdateFound = () => {
    const answer = window.confirm(
        "This application has been updated. " + "Reload to display the latest version?"
    );
    if (answer === true) {
        window.location.reload(true)
    }
};*/
