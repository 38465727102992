// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-360-js": () => import("./../../../src/pages/360.js" /* webpackChunkName: "component---src-pages-360-js" */),
  "component---src-pages-4-cs-js": () => import("./../../../src/pages/4cs.js" /* webpackChunkName: "component---src-pages-4-cs-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activity-js": () => import("./../../../src/pages/Activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-certified-diamonds-js": () => import("./../../../src/pages/certified-diamonds.js" /* webpackChunkName: "component---src-pages-certified-diamonds-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-social-responsibility-js": () => import("./../../../src/pages/corporate-social-responsibility.js" /* webpackChunkName: "component---src-pages-corporate-social-responsibility-js" */),
  "component---src-pages-customer-care-concierge-services-js": () => import("./../../../src/pages/customer-care/concierge-services.js" /* webpackChunkName: "component---src-pages-customer-care-concierge-services-js" */),
  "component---src-pages-customer-care-fa-qs-js": () => import("./../../../src/pages/customer-care/FAQs.js" /* webpackChunkName: "component---src-pages-customer-care-fa-qs-js" */),
  "component---src-pages-customer-testimonials-js": () => import("./../../../src/pages/customer-testimonials.js" /* webpackChunkName: "component---src-pages-customer-testimonials-js" */),
  "component---src-pages-cut-and-polished-diamonds-js": () => import("./../../../src/pages/cut-and-polished-diamonds.js" /* webpackChunkName: "component---src-pages-cut-and-polished-diamonds-js" */),
  "component---src-pages-device-feedback-js": () => import("./../../../src/pages/device/feedback.js" /* webpackChunkName: "component---src-pages-device-feedback-js" */),
  "component---src-pages-device-help-js": () => import("./../../../src/pages/device/help.js" /* webpackChunkName: "component---src-pages-device-help-js" */),
  "component---src-pages-diamonds-online-js": () => import("./../../../src/pages/diamonds-online.js" /* webpackChunkName: "component---src-pages-diamonds-online-js" */),
  "component---src-pages-dudhala-work-js": () => import("./../../../src/pages/DudhalaWork.js" /* webpackChunkName: "component---src-pages-dudhala-work-js" */),
  "component---src-pages-entity-brand-js": () => import("./../../../src/pages/entity-brand.js" /* webpackChunkName: "component---src-pages-entity-brand-js" */),
  "component---src-pages-fancy-diamonds-js": () => import("./../../../src/pages/fancy-diamonds.js" /* webpackChunkName: "component---src-pages-fancy-diamonds-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-hk-achievements-js": () => import("./../../../src/pages/hk-achievements.js" /* webpackChunkName: "component---src-pages-hk-achievements-js" */),
  "component---src-pages-hk-grading-js": () => import("./../../../src/pages/HK-grading.js" /* webpackChunkName: "component---src-pages-hk-grading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loose-diamonds-js": () => import("./../../../src/pages/loose-diamonds.js" /* webpackChunkName: "component---src-pages-loose-diamonds-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-news-media-js": () => import("./../../../src/pages/news-media.js" /* webpackChunkName: "component---src-pages-news-media-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-presence-js": () => import("./../../../src/pages/presence.js" /* webpackChunkName: "component---src-pages-presence-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-siic-js": () => import("./../../../src/pages/siic.js" /* webpackChunkName: "component---src-pages-siic-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-water-sports-js": () => import("./../../../src/pages/WaterSports.js" /* webpackChunkName: "component---src-pages-water-sports-js" */),
  "component---src-pages-wholesale-diamonds-js": () => import("./../../../src/pages/wholesale-diamonds.js" /* webpackChunkName: "component---src-pages-wholesale-diamonds-js" */),
  "component---src-pages-wholesale-loose-diamonds-australia-js": () => import("./../../../src/pages/Wholesale-Loose-Diamonds/Australia.js" /* webpackChunkName: "component---src-pages-wholesale-loose-diamonds-australia-js" */),
  "component---src-pages-wholesale-loose-diamonds-hong-kong-js": () => import("./../../../src/pages/Wholesale-Loose-Diamonds/Hong-Kong.js" /* webpackChunkName: "component---src-pages-wholesale-loose-diamonds-hong-kong-js" */),
  "component---src-pages-wholesale-loose-diamonds-israel-js": () => import("./../../../src/pages/Wholesale-Loose-Diamonds/Israel.js" /* webpackChunkName: "component---src-pages-wholesale-loose-diamonds-israel-js" */),
  "component---src-pages-wholesale-loose-diamonds-uk-js": () => import("./../../../src/pages/Wholesale-Loose-Diamonds/Uk.js" /* webpackChunkName: "component---src-pages-wholesale-loose-diamonds-uk-js" */),
  "component---src-pages-why-hk-js": () => import("./../../../src/pages/why-hk.js" /* webpackChunkName: "component---src-pages-why-hk-js" */)
}

