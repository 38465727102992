import {SWITCH_LANGUAGE} from '../../constants/ActionTypes';

const INIT_STATE = {
    locale: typeof localStorage !== 'undefined' && localStorage?.getItem('hk-localisation')
        ? JSON.parse(localStorage.getItem('hk-localisation'))
        : {languageId: "english", locale: "en", name: "English", icon: "us"},
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload
            };
        default:
            return state;
    }
};